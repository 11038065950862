<script setup>
import { JHeaderProfileV2 } from "media-flow-ui"

import { usePageBuilder } from "~/stores/page-builder"
import { useAuthBuilder } from "~/stores/auth-builder"

import { storeToRefs } from "pinia"
import CookieStorage from "~/services/CookieStorage"

import { formatProfileStyle } from "@/helpers/header/profile"
import { $$t } from "~~/utils/i19m"
import { formatIconStyle } from "~/helpers/icon/icon"

const pageBuilderStore = usePageBuilder()
const authBuilderStore = useAuthBuilder()

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
  onToggle: {
    type: Function,
    required: true,
  },
  onClose: {
    type: Function,
    default: () => {},
    required: false,
  },
})

const { user } = storeToRefs(authBuilderStore)

const login = async () => {
  try {
    
    await authBuilderStore.callLogin()
  } catch (error) {
    console.error("Login click", error)
  }
}

const logout = async () => {
  const cookie = new CookieStorage()
  cookie.removeItem("mAccessToken")

  authBuilderStore.unsetUserOnCookie()

  await authBuilderStore.callLogOut()
}

const formattedData = computed(() => {
  return {
    ...props.data,
    style: formatProfileStyle(props.data.style),
  }
})

const iconsStyle = formatIconStyle(props.data.icon)
</script>

<template>
  <!-- {{ formattedData }} -->
  <JHeaderProfileV2
    :class="'order-' + data.order"
    class="items-center justify-center flex"
    :is-open="isOpen"
    :on-toggle="onToggle"
    :on-close="onClose"
    :breakpoint="breakpoint"
    :data="formattedData"
    :user-data="{
      accountName: user?.name,
      name: user?.name,
      surname: user?.surname,
      accountPicture: user?.picture,
      accountProfileUrl:
        pageBuilderStore?.providersData?.issuer ||
        'https://myaccount.fameplay.tv/',
    }"
    :icons="iconsStyle"
    :login-callback="login"
    :logout-callback="logout"
    :log-out-text="$$t('component_header_profile_log_out')"
  />
</template>

<style></style>
